import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import forEach from "lodash/forEach";
import flatten from "lodash/flatten";
import { DownloadsService } from "@services/downloads.service";
import { Component as BrComponent, Page } from "@bloomreach/spa-sdk";
import { FundReportPageCategory } from "src/app/ft-constants/fund-reports";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@components/shared/translate/translate.service";
import { Observable, Subscriber, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import moment from "moment";

@Component({
  selector: "ft-fund-reports",
  templateUrl: "./fund-reports.component.html",
  styleUrls: ["./fund-reports.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FundReportsComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  selectedDropdown: any;
  fundDocuments: any;
  errorInService: boolean;
  isLoaded = false;
  documentName: { allDocs: []; docNames: [] };
  showDocumentPanel = true;
  reportCategories: any;
  serviceStatus = "Loading";
  hasMultipleFilters = false;
  pageCategory: string;

  // Content from BR
  categoryDocContent: any;

  // Determine from BR page type/url
  downloadPageCategory: string;
  selectedSecondaryFilterItem: string;

  // Check if deep linked filters are present
  hasDeepLinkedFilter: boolean;
  selectedTab: any = {};
  selectedCategory: string = "";
  categories: string[] = [];
  selectedCategoryData: any[] = [];

  selectedMenuName: string = "";

  //  search bar
  fundsForSearch: any = [];
  fullFundsArray: any = [];
  searchTerm: string = "";
  funds: any = [];
  filterDropdowns: any[];
  searchedInput = "";
  selectedDocuments: any;
  searchedInput$: Subscriber<string>;
  dataSubscription: Subscription;
  searchSuggestions: string[] = [];
  showNoSearchSuggestionMessage = false;
  searchedInputAgainstNoSearchSuggestionMessage: string;
  secondaryFilters: any;
  clearTextBox = false;
  count: number;
  resultLimit = 10;
  fromDate: string;
  toDate: string;
  dateFormatForComponent = "DD/MM/YYYY";
  currentDateStd: string;

  public selectedFilterItem: string;

  @HostListener("click", ["$event"])
  getTableDataPage(event: Event) {
    if (
      (event.target as Element).className ===
      "form-control search ng-untouched ng-pristine ng-valid" ||
      (event.target as Element).className ===
      "form-control search ng-valid ng-dirty ng-touched" ||
      (event.target as Element).className ===
      "form-control search ng-valid ng-touched ng-dirty"
    ) {
      this.fundsForSearch = this.fullFundsArray;
    } else {
      this.fundsForSearch = [];
    }
  }

  constructor(
    private downloadsService: DownloadsService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.pageCategory = this.component?.getModels().config?.documentCategory;
    switch (this.pageCategory) {
      case FundReportPageCategory.formsInstructions:
        this.downloadPageCategory =
          FundReportPageCategory.formsInstructionsValue;
        break;
      case FundReportPageCategory.fundDocuments:
        this.downloadPageCategory = FundReportPageCategory.fundDocumentsValue;
        break;
      case FundReportPageCategory.fundLiterature:
        this.downloadPageCategory = FundReportPageCategory.fundLiteratureValue;
        break;
      case FundReportPageCategory.disclosureDocument:
        this.downloadPageCategory =
          FundReportPageCategory.disclosureDocumentValue;
        break;
      case FundReportPageCategory.updates:
        this.downloadPageCategory = FundReportPageCategory.updatesValue;
        break;
      case FundReportPageCategory.report:
        this.downloadPageCategory = FundReportPageCategory.reportsValue;
        break;
      default:
        this.downloadPageCategory = this.getDefaultDeepLinkingFilterParams();
    }

    const pullDataForCategory =
      this.pageCategory === "Reports"
        ? FundReportPageCategory.report
        : FundReportPageCategory.download;

    this.downloadsService.getData$(pullDataForCategory)?.subscribe(
      (response) => {
        this.currentDateStd = moment(new Date()).format("YYYY-MM-DD");
        this.isLoaded = true;
        this.reportCategories = response;

        if (this.reportCategories?.hasOwnProperty("PageType")) {
          // For downloads
          this.hasMultipleFilters = true;

          // determine if content is present
          forEach(this.reportCategories?.PageType, (category) => {
            forEach(category.FirstDropDown, (documentType, key) => {
              if (documentType.isCategoryDocContent) {
                // Get document data from BR
                const documents = this.component?.getModels()?.document;
                this.categoryDocContent = this.page
                  ?.getContent(documents)
                  ?.getData().contentBlocks;

                // Remove placeholder BR Content
                category.FirstDropDown.splice(key);

                // pull the required date
                const sanitizedDocContent = flatten(
                  this.categoryDocContent?.map((content) => ({
                    isContent: true,
                    id: content?.title,
                    markup: content?.content?.content?.value,
                  }))
                );

                // push sanitized data to document list
                forEach(sanitizedDocContent, (eachContentBlock) => {
                  category.FirstDropDown.push(eachContentBlock);
                });
              }
            });
          });

          // END

          this.fundDocuments = this.getDocumentsByCategory(
            this.reportCategories?.PageType,
            this.downloadPageCategory
          );
        } else {
          // For reports
          this.fundDocuments = this.reportCategories.FirstDropDown;
        }

        if (!this.fundDocuments) {
          this.serviceStatus = "No documents found. Please try after sometime.";
          this.isLoaded = false;
        }

        this.documentName = this.documentNames(this.fundDocuments, {
          allDocs: [],
          docNames: [],
        });

        // check if 'all' sub category is present
        if (this.hasMultipleFilters) {
          this.selectedDropdown = "ALL";
        } else {
          this.selectedDropdown = this.fundDocuments
            ? this.fundDocuments[0]?.id
            : "FUND-FACTSHEETS";
        }
        // if present run a loop
        if (this.reportCategories?.hasOwnProperty("PageType")) {
          this.hasMultipleFilters = true;

          // Set default dropdown
          this.selectedFilterItem = this.downloadPageCategory;

          // Set filter dropdown data
          this.filterDropdowns = [
            this.simplifyreportCategories(
              this.reportCategories?.PageType,
              this.downloadPageCategory
            ),
          ];

          // Add 'All' dropdown item in second dropdown
          const all = {
            dataRecords: {linkdata: []},
            id: "ALL",
            isCategoryDocContent: false,
            secondDropDown: [],
            label: this.translateService.instant("literature.ALL"),
            value: "ALL",
            selected: true,
          };

          forEach(this.filterDropdowns, (dropdownItem) => {
            forEach(dropdownItem, (item) => {
              // Check if there is more than one subcategory
              if (item.FirstDropDown.length > 1) {
                item.FirstDropDown = this.simplifyreportCategories(
                  item.FirstDropDown,
                  null
                );
                item.FirstDropDown.unshift(all);
              }
            });
          });
        } else {
          this.filterDropdowns = [
            this.simplifyreportCategories(
              this.reportCategories?.FirstDropDown,
              this.downloadPageCategory
            ),
          ];
        }

        if (!this.secondaryFilters) {
          this.secondaryFilters = this.populateFilterValues(
            this.filterDropdowns,
            this.downloadPageCategory
          );
        }
        // Get filter from router and load the components accordingly
        this.getDeepLinkingFilterParams();
        this.cdr.detectChanges();
      },
      (error) => {
        this.serviceStatus =
          error.error.message + ". Please try after sometime.";
        this.cdr.detectChanges();
      }
    );
  }

  // Add properties required for Drop Down
  simplifyreportCategories = (input, defaultCategory) => {
    return input?.map((dropDown) => ({
      ...dropDown,
      label: dropDown.id
        ? this.translateService.instant("literature." + dropDown.id)
        : this.translateService.instant("literature." + dropDown.name),
      value: dropDown.id ? dropDown.id : dropDown.name,
      selected:
        dropDown.name === defaultCategory
          ? dropDown.name === defaultCategory
          : false,
    }));
  };

  populateFilterValues(input, selector) {
    return flatten(input).filter((data) => {
      return data?.name === selector;
    })[0];
  }

  onClearSearch() {
    this.searchedInput = "";
    this.searchedInputAgainstNoSearchSuggestionMessage = "";
    this.clearTextBox = true;
    this.searchSuggestions = [];
    this.showDocumentPanel = true;
    this.resultsFoundEmitter();
  }

  getDefaultDeepLinkingFilterParams = (): string => {
    this.hasDeepLinkedFilter =
      this.route.snapshot.queryParams?.hasOwnProperty("firstFilter");
    return this.route.snapshot.queryParams?.firstFilter;
  };

  getDeepLinkingFilterParams = (): any => {
    this.hasDeepLinkedFilter =
      this.route.snapshot.queryParams?.hasOwnProperty("secondFilter");

    if (this.hasDeepLinkedFilter) {
      const firstFilter = parseInt(
        this.route.snapshot.queryParams?.firstFilter,
        10
      );
      let secondFilter = parseInt(
        this.route.snapshot.queryParams?.secondFilter,
        10
      );
      // check if filter identification is valid
      if (!firstFilter && secondFilter) {
        // Single dropdown
        if (secondFilter <= this.reportCategories?.FirstDropDown?.length) {
          this.downloadPageCategory =
            this.reportCategories.FirstDropDown[secondFilter - 1]?.id;
          const selectedTabData = this.filterDropdowns[0].find(
            (data) => data.id == this.downloadPageCategory
          );
          this.selectTab(selectedTabData, false);
        }
      }

      if (firstFilter && secondFilter) {
        // multi dropdowns
        if (firstFilter <= this.reportCategories?.PageType?.length) {
          this.downloadPageCategory =
            this.reportCategories.PageType[firstFilter - 1]?.name;
        }
        if (secondFilter <= this.secondaryFilters?.FirstDropDown?.length) {
          this.secondaryFilters = this.populateFilterValues(
            this.filterDropdowns,
            this.downloadPageCategory
          );
        } else {
          secondFilter = 1;
        }
        this.selectedFilterItem = this.downloadPageCategory;
        this.selectedSecondaryFilterItem = this.translateService.instant(
          "literature." +
          this.secondaryFilters?.FirstDropDown[secondFilter - 1]?.id
        );
        const selectedTabData = this.filterDropdowns[0].find(
          (data) => data.name == this.downloadPageCategory
        );
        this.selectTab(selectedTabData, true);
      }

      this.cdr.detectChanges();
    } else {
      const selectedTabData = this.filterDropdowns[0].filter(
        (data) => data.name == this.downloadPageCategory
      );
      this.selectTab(selectedTabData[0], false);
    }
  };

  resultsFoundEmitter() {
    this.onResultsFound(this.showDocumentPanel);
  }

  getDocumentsByCategory = (documents, category) => {
    return documents?.filter((res) => res?.name === category)[0]?.FirstDropDown;
  };

  documentNames(input, docInfo) {
    forEach(input, (docs) => {
      if (docs.dataRecords?.linkdata.length > 0) {
        forEach(docs.dataRecords?.linkdata, (doc) => {
          docInfo.allDocs.push(doc);
          if (doc.dctermsTitle) {
            docInfo.docNames.push(doc.dctermsTitle);
          }
        });
      } else {
        this.documentNames(docs.secondDropDown, docInfo);
      }
    });
    return docInfo;
  }

  onTabChanged(selectedTabName: any) {
    if (this.reportCategories?.hasOwnProperty("PageType")) {
      this.fundDocuments = this.getDocumentsByCategory(
        this.reportCategories?.PageType,
        selectedTabName
      );
    }
    // Re-initiate document names as per selected category, once user change selected category
    this.documentName = this.documentNames(this.fundDocuments, {
      allDocs: [],
      docNames: [],
    });
  }

  onResultsFound(event) {
    this.showDocumentPanel = event;
  }


  selectTab(tab: any, hasFilter?: boolean): void {
    this.fromDate = "";
    this.toDate = "";
    this.categories = [];
    this.onClearSearch();
    this.selectedMenuName = tab["label"];
    this.selectedTab = tab;
    if (tab.secondDropDown?.length) {
      const tabCategories: any[] = [];

      tab.secondDropDown.forEach((t: any) => {
        if (
          !tabCategories.includes(
            this.translateService.instant("literature." + t.id)
          )
        ) {
          tabCategories.push(
            this.translateService.instant("literature." + t.id)
          );
        }
      });

      this.categories = tabCategories;
    }
    if (tab.FirstDropDown?.length) {
      const tabCategories: any[] = [];
      tab.FirstDropDown.forEach((t: any) => {
        if (
          !tabCategories.includes(
            this.translateService.instant("literature." + t.id)
          )
        ) {
          tabCategories.push(
            this.translateService.instant("literature." + t.id)
          );
        }
      });

      this.categories = tabCategories;
    }

    const selectedFilterCategory = hasFilter
      ? this.selectedSecondaryFilterItem
      : this.categories[0];
    this.selectCategory(selectedFilterCategory);
    this.onTabChanged(tab["name"]);
  }

  selectTabByDropDown(event: any, hasFilter?: boolean) {
    this.categories = [];
    this.selectedMenuName = event.target.value;
    let tab = this.reportCategories.FirstDropDown.filter((x: any) => {
      return (
        this.translateService.instant("literature." + x.id) ==
        this.selectedMenuName
      );
    })[0];
    this.selectedTab = tab;

    if (tab.secondDropDown?.length) {
      const tabCategories: any[] = [];

      tab.secondDropDown.forEach((t: any) => {
        if (
          !tabCategories.includes(
            this.translateService.instant("literature." + t.id)
          )
        ) {
          tabCategories.push(
            this.translateService.instant("literature." + t.id)
          );
        }
      });

      this.categories = tabCategories;
    }
    if (tab.FirstDropDown?.length) {
      const tabCategories: any[] = [];
      tab.FirstDropDown.forEach((t: any) => {
        if (
          !tabCategories.includes(
            this.translateService.instant("literature." + t.id)
          )
        ) {
          tabCategories.push(
            this.translateService.instant("literature." + t.id)
          );
        }
      });

      this.categories = tabCategories;
    }
    const selectedFilterCategory = hasFilter
      ? this.selectedSecondaryFilterItem
      : this.categories[0];
    this.selectCategory(selectedFilterCategory);
  }

  selectCategory(category: string | any): void {
    this.selectedCategory = category?.target?.value
      ? category.target.value
      : category;
    this.selectedCategoryData = [];
    if (this.selectedTab?.secondDropDown?.length) {
      let selectedCategoryItem = this.selectedTab.secondDropDown.filter(
        (item) => {
          return (
            this.translateService.instant("literature." + item.id) ===
            this.selectedCategory
          );
        }
      )[0];
      this.selectedCategoryData = selectedCategoryItem.dataRecords.linkdata;
    } else if (this.selectedTab.FirstDropDown?.length) {
      if (this.selectedCategory == "All" && this.pageCategory != "Reports") {
        this.selectedCategoryData = this.selectedTab.FirstDropDown.filter(
          (item) => item.label != "All"
        );
      } else {
        let selectedCategoryItem = this.selectedTab.FirstDropDown.filter(
          (item) => {
            return (
              this.translateService.instant("literature." + item.id) ===
              this.selectedCategory
            );
          }
        )[0];
        if (!selectedCategoryItem.isContent) {
          this.selectedCategoryData = selectedCategoryItem?.dataRecords
            ?.linkdata?.length
            ? selectedCategoryItem.dataRecords.linkdata
            : selectedCategoryItem.secondDropDown;
        } else {
          this.selectedCategoryData = selectedCategoryItem;
        }
      }
    } else {
      this.selectedCategoryData = this.selectedTab.dataRecords.linkdata;
    }
  }

  public getDocumentURL(input) {
    return input?.literatureHref.indexOf("http") > -1
      ? input.literatureHref
      : "download" + input.literatureHref;
  }

  // Capture file extension from document url (if filetype is not present)
  public fileExtension(input) {
    const extensionIndex = input?.fileExtension
      ? input?.fileExtension
      : input?.literatureHref.lastIndexOf(".");
    if (input?.fileExtension !== "NA") {
      if (input?.fileExtension && input?.fileExtension !== "") {
        return extensionIndex?.toLowerCase();
      } else {
        return input?.literatureHref?.slice(extensionIndex + 1).toLowerCase();
      }
    }
  }

  /**
   * Triggered when any text in entered in search input.
   * @param event generated input event
   */
  onSearchInput(event: InputEvent): void {
    const searchedText: string = (event.target as HTMLInputElement).value;

    // Reset clearTextBox value to false so that suggestions will be visible
    this.clearTextBox = false;

    // Create new observable and assign its subscriber to search text subscriber.
    // This will indirectly be used to debounce the key input to currently 500 ms.
    // Also, not to omit event when the new value is not distinct.
    if (!this.searchedInput$) {
      this.dataSubscription = new Observable<string>((observer) => {
        this.searchedInput$ = observer;
      })
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((fullSearchedText) => {
          this.searchedInput = fullSearchedText.trim(); // Remove empty spaces in the search input.

          if (this.searchedInput) {
            const literatureNames: string[] = this.documentName.docNames;
            this.searchSuggestions = literatureNames.filter((fundName) =>
              fundName.toLowerCase().includes(this.searchedInput.toLowerCase())
            );

            // Show "showNoSearchSuggestionMessage" only when filter returns empty list.
            if (this.searchSuggestions.length) {
              this.showNoSearchSuggestionMessage = false;
              // this.showDocumentPanel = true;
            } else {
              this.searchedInputAgainstNoSearchSuggestionMessage =
                this.searchedInput;
              this.showNoSearchSuggestionMessage = true;
              // this.showDocumentPanel = false;
            }
          } else {
            this.showNoSearchSuggestionMessage = false; // For blank search input, need not to show message.
            this.searchSuggestions = []; // Make it blank, as it can be case, user cleared the search input.

            this.resultsFoundEmitter();
            this.showDocumentPanel = true;
            // this.filterEvent.next({
            //   searchedText: this.searchedInput,
            // });
          }
          // Reset limit
          this.resultLimit = 10;
          this.cdr.detectChanges();
        });
    }

    this.searchedInput$.next(searchedText);
  }

  /**
   * Trigger search filter based upon selected search suggestion.
   * @param selectedSuggestion selected suggestion from the search suggestion list
   */
  onSearchSuggestionSelected(selectedSuggestion: any): void {
    if (selectedSuggestion.length) {
      this.showDocumentPanel = false;
      this.resultsFoundEmitter();
      this.searchSuggestions = [];

      if (typeof selectedSuggestion !== "string") {
        this.selectedDocuments = this.getSearchedDocuments(
          selectedSuggestion,
          []
        );
      } else {
        const literatureDocuments: any = this.documentName.allDocs;
        this.searchedInput = selectedSuggestion;
        this.selectedDocuments = literatureDocuments.filter(
          (doc) => doc.dctermsTitle === selectedSuggestion
        );
      }

      this.count = this.selectedDocuments.length;
      const searchItemURL = this.getDocumentURL(this.selectedDocuments[0]);
      window.open(searchItemURL, "_blank");
    }
  }

  getSearchedDocuments(input, results) {
    forEach(input, (docs) => {
      if (input.length > 0) {
        forEach(this.documentName.allDocs, (doc) => {
          if (doc.dctermsTitle === docs) {
            results.push(doc);
          }
        });
      }
    });
    return results;
  }

  applyFilter() {
    const fromdate = moment(this.fromDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    const todate = moment(this.toDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    const allDocs: any = this.documentName.allDocs;
    if (this.fromDate && this.toDate) {
      const selectedMembers = allDocs.filter(
        (m) => m.frkReferenceDate >= fromdate && m.frkReferenceDate <= todate
      );

      const filteredData = selectedMembers.filter((item) => {
        return (
          this.translateService.instant("literature." + item.id) ==
          (this.selectedCategory ?? this.selectedTab.label)
        );
      });
      this.selectedCategoryData = filteredData;
      const modal = document.getElementById("offcanvasBottomFilter");
      const modalBackdrop =
        document.getElementsByClassName("offcanvas-backdrop");
      if (modal) {
        modal.classList.remove("show");
        modalBackdrop[0].classList.remove("show");
      }
      this.cdr.detectChanges();
    }
  }

  onFinalDate(selectedDate: string, dateField: string): void {
    if (dateField === "fromDate") {
      this.fromDate = selectedDate;
    } else {
      this.toDate = selectedDate;
    }
    this.cdr.detectChanges();
  }

  onCategoryChange(value: any) {
    this.selectedCategory = value;
  }

  clearFilter() {
    this.fromDate = "";
    this.toDate = "";
  }

  scrollToBottom() {
    const scrollBar = document.querySelector('.scrollable-container');
    if (scrollBar) {
      if (scrollBar.scrollTop + scrollBar.clientHeight !== scrollBar.scrollHeight) {
        scrollBar.scrollTop += 200; // Scrolls down by 200px
      } else {
        scrollBar.scrollTop = scrollBar.scrollHeight; // Scrolls to the bottom
      }
    }
  }
}
