<div id="updates-banners" [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <ng-container
      *ngIf="page?.isPreview()"
      [brManageContentButton]="document"
      documentTemplateQuery="new-ftindia-document"
      folderTemplateQuery="new-ftindia-folder"
      parameter="document"
      [relative]="false"
      root="">
  </ng-container><!-- Banner Section Started Here...-->
  <section class="FT-hero ind-carousel-main" *ngIf="isHomePage; else otherCarousel">
    <div id="carouselExampleCaptions" class="carousel slide swiper" data-bs-ride="carousel" data-bs-interval="3000">
      <div class="carousel-indicators">
        <button type="button" *ngFor="let result of CarouselImages; let i = index"
                data-bs-target="#carouselExampleCaptions" [attr.data-bs-slide-to]="i"
                [attr.aria-current]="{ true: i === 0 }" [attr.aria-label]="'Slide '+i"
                [ngClass]="{ active: i === 0 }">
        </button>
      </div>
      <div class="carousel-inner">
        <div class="analytics-carousel-link quicAction-link redirectGuestUrl a1 carousel-item"
             style="height:fit-content;max-height: 500px"
             *ngFor="let result of CarouselImages; let i = index"
             [ngClass]="{ active: i === 0, 'carousel-with-content': result?.description?.value!=='' }"
             data-action="Banner click">
          <a *ngIf="result?.description?.value === ''" [href]="result?.linkCompound?.linkCollection[0]?.url"
             target="{{result?.linkCompound?.linkCollection[0]?.target}}" (click)="setAnalytics(result?.title)">
            <div style="overflow: hidden;">
              <img [attr.fetchpriority]="i ===0? 'high': null" class="d-none d-sm-block w-100 banner-images noLazy"
                   src="{{getImageURL(result?.imageForDesktop?.widenDocument)}}" alt="{{ result.title }}"
                   style="object-fit: cover!important;">
            </div>
            <div class="d-block d-sm-none text-center banner4-sm new">
              <img alt="{{ result.title }}" class="noLazy" src="{{getImageURL(result?.imageForMobile?.widenDocument)}}">
            </div>
            <div class="container text-white" style="position: absolute; top: 0;">
              <h2 *ngIf="result.title" class="title-align">{{ result.title }}</h2>
              <p>{{ result.altTitle }}</p>
            </div>
          </a>
          <ng-container *ngIf="result?.description?.value !== ''">
            <div style="overflow: hidden;">
              <img src="{{setBackgroundUrl(result)}}" alt="{{ result.title }}"
                   class="d-none d-sm-block w-100 banner-images"
                   style="object-fit: cover!important;">
            </div>
            <div class="d-block d-sm-none text-center banner4-sm new">
              <img src="{{setBackgroundUrl(result)}}" alt="{{ result.title }}">
            </div>
            <div class="container text-white" style="position: absolute; top: 0;"
                 *ngIf="result?.description?.value !== ''">
              <div class="row">
                <div class="col-12 d-block d-md-none mb-2 text-center" *ngIf="result?.description?.value!==''">
                  <img alt="Background Image" src="{{getImageURL(result?.imageForMobile?.widenDocument)}}">
                </div>
                <div class="col-md col-12">
                  <h2 *ngIf="result?.linkCompound?.linkCollection[0]?.externalSuffix !== '' && result?.description?.value!== '' ">
                    <img alt="Info"
                         [src]="result?.linkCompound?.linkCollection[0]?.externalSuffix">{{ result?.altTitle }}</h2>
                  <span [innerHTML]="result?.description?.value"></span>
                  <a *ngIf="result?.linkCompound?.linkCollection[0]?.url"
                     href="{{getLinkCollectionUrl(result)}}"
                     target="{{result?.linkCompound?.linkCollection[0]?.target}}"
                     class="btn btn-white">{{ result?.linkCompound?.linkCollection[0]?.displayText }}</a>
                </div>
                <div class="col-md-auto d-none d-md-block" *ngIf="result?.description?.value!=='' ">
                  <img alt="{{ result?.linkCompound?.linkCollection[0]?.displayText }}"
                       src="{{ getImageURL(result?.imageForDesktop?.widenDocument) }}">
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
              data-bs-slide="prev">
        <i class="bi bi-arrow-left-short banner"></i>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
              data-bs-slide="next">
        <i class="bi bi-arrow-right-short banner"></i>
      </button>
    </div>
  </section>

  <ng-template #otherCarousel>
    <section class="FT-hero inner-page">
      <div id="carouselKCCaptions" class="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
        <div class="carousel-indicators">
          <button
              type="button"
              *ngFor="let result of CarouselImages; let i = index"
              data-bs-target="#carouselKCCaptions"
              [attr.data-bs-slide-to]="i"
              [attr.aria-current]="{ true: i === 0 }"
              aria-label="Slide i"
              [ngClass]="{ active: i === 0 }">
          </button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item KC-carousel-item"
               *ngFor="let result of CarouselImages; let i = index"
               [ngClass]="{ active: i === 0 }"
               style='background:url("{{setBackgroundUrl(result)}}")'>
            <div class="container text-white" [innerHTML]="result?.description?.value"></div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselKCCaptions"
                data-bs-slide="prev">
          <i class="bi bi-arrow-left-short"></i>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselKCCaptions"
                data-bs-slide="next">
          <i class="bi bi-arrow-right-short"></i>
        </button>
      </div>
    </section>
  </ng-template>
</div>
