<form [formGroup]="investmentCalcForm" *ngIf="investmentCalcForm">

  <!-- Form-1: Earn | Target amount -->
  <div class="form-group" *ngIf="isTargeted">
    <label for="customRange4" id="needToEarn" class="form-label">{{ "ftiCalc.calculator-need-to-earn" | translate }}
      <div class="selected-range" (click)="autoFocus($event, 'ref-earn')">
        <span class="calc-input-prefix">₹</span>
        <input
            name="earn"
            type="number"
            (change)="onCalculatorChange($event, 'earn')"
            [(ngModel)]="investmentCalcForm.value.earn"
            formControlName="earn"
            [attr.min]="getInputLimit(sliderKeyName.earn, false)"
            [attr.max]="getInputLimit(sliderKeyName.earn, true)"
            class="calc-input amount text-start" id="ref-earn">
      </div>
    </label>
    <div class="range-slider">
      <input
          type="range"
          (change)="onCalculatorChange($event, 'earn')"
          [(ngModel)]="investmentCalcForm.value.earn"
          formControlName="earn"
          class="custom-range"
          [attr.min]="getInputLimit(sliderKeyName.earn, false)"
          [attr.max]="getInputLimit(sliderKeyName.earn, true)"
          [style.background]="earnSliderStyle"
          aria-labelledby="needToEarn"
          >
    </div>
    <div class="d-flex text-small justify-content-between">
      <span>₹{{ "ftiCalc.calculator-50L" | translate }}</span>
      <span>₹{{ "ftiCalc.calculator-10Cr" | translate }}</span>
    </div>
  </div>
  <div class="form-group" *ngIf="!(!isSIP && isTargeted)">
    <!-- Form-2: Input Invest Amount -->
    <label for="ref-amount" class="form-label"
           *ngIf="!isTargeted" id="sipCan">{{ isTargeted ? ("ftiCalc.calculator-sip-can" | translate) : ("ftiCalc.calculator-sip-want-to" | translate) }} {{ "ftiCalc.calculator-invest-text" | translate }} {{ isSIP ? '' : ("ftiCalc.calculator-lumpsum-text1" | translate) }}
      <div class="selected-range" (click)="autoFocus($event, 'ref-amount')">
        <span class="calc-input-prefix">₹</span>
        <input
            type="number"
            [attr.min]="getInputLimit(sliderKeyName.amount, false)"
            [attr.max]="getInputLimit(sliderKeyName.amount, true)"
            [(ngModel)]="investmentCalcForm.value.amount"
            formControlName="amount"
            (change)="onCalculatorChange($event, 'amount')"
            class="calc-input amount text-start" id="ref-amount"
            aria-labelledby="sipCan">

      </div>
    </label>

    <!-- Form-3: Duration | Frequency -->
    <div class="invest-options" *ngIf="isSIP">
      <div class="form-check">
        <input
            formControlName="time"
            (change)="onCalculatorChange($event, 'time')" value="DAILY" class="form-check-input" type="radio"
            name="time"
            id="Daily">
        <label class="form-check-label" for="Daily">
          {{ "ftiCalc.calculator-lumpsum-text2" | translate }}
        </label>
      </div>
      <div class="form-check">
        <input formControlName="time"
               (change)="onCalculatorChange($event, 'time')"
               value="WEEKLY" class="form-check-input" type="radio" name="time"
               id="Weekly" checked>
        <label class="form-check-label" for="Weekly">
          {{ "ftiCalc.calculator-lumpsum-text3" | translate }}
        </label>
      </div>
      <div class="form-check">
        <input formControlName="time"
               (change)="onCalculatorChange($event, 'time')"
               value="MONTHLY" class="form-check-input" type="radio" name="time"
               id="Monthly">
        <label class="form-check-label" for="Monthly">
          {{ "ftiCalc.calculator-lumpsum-text4" | translate }}
        </label>
      </div>
      <div class="form-check">
        <input formControlName="time"
               (change)="onCalculatorChange($event, 'time')"
               value="QUARTERLY" class="form-check-input" type="radio" name="time"
               id="Quarterly">
        <label class="form-check-label" for="Quarterly">
          {{ "ftiCalc.calculator-quarterly-text1" | translate }}
        </label>
      </div>
    </div>

    <!-- Form-2: Range Invest Amount -->
    <div class="range-slider" *ngIf="!isTargeted">
      <input
          type="range"
          [attr.min]="getInputLimit(sliderKeyName.amount, false)"
          [attr.max]="getInputLimit(sliderKeyName.amount, true)"
          [(ngModel)]="investmentCalcForm.value.amount"
          formControlName="amount"
          (change)="onCalculatorChange($event, 'amount')"
          class="custom-range"
          [style.background]="amountSliderStyle"
          aria-labelledby="calcAmount">

    </div>
    <div id="calcAmount" *ngIf="!isTargeted" class="d-flex text-small justify-content-between">
      <span>₹{{ isSIP ? ("ftiCalc.calculator-500" | translate) : ("ftiCalc.calculator-10k" | translate) }}</span>
      <span>₹{{ isSIP ? ("ftiCalc.calculator-10L" | translate) : ("ftiCalc.calculator-10Cr" | translate) }}</span>
    </div>
  </div>

  <!-- Form-4: Period Year -->
  <div class="form-group ">
    <label for="ref-amount"
           class="form-label">{{ isTargeted ? ("ftiCalc.calculator-over" | translate) : ("ftiCalc.calculator-for-a" | translate) }} {{ "ftiCalc.calculator-period-of" | translate }}
      <div class="selected-range" (click)="autoFocus($event, 'ref-period')">
        <input
            type="number"
            [attr.min]="getInputLimit(sliderKeyName.period, false)"
            [attr.max]="getInputLimit(sliderKeyName.period, true)"
            formControlName="period"
            [(ngModel)]="investmentCalcForm.value.period"
            (change)="onCalculatorChange($event, 'period')"
            class="calc-input"  id="ref-period" aria-labelledby="years">
        <span class="calc-input-prefix ms-1" id="years">{{ "ftiCalc.calculator-years" | translate }}</span>
      </div>
    </label>
    <div class="range-slider">
      <input
          type="range"
          formControlName="period"
          [(ngModel)]="investmentCalcForm.value.period"
          (change)="onCalculatorChange($event, 'period')"
          class="custom-range"
          [attr.min]="getInputLimit(sliderKeyName.period, false)"
          [attr.max]="getInputLimit(sliderKeyName.period, true)"
          [style.background]="periodSliderStyle" aria-labelledby="calcYear">
    </div>
    <div id="calcYear" class="d-flex text-small justify-content-between">
      <span>{{ "ftiCalc.calculator-one-year" | translate }}</span>
      <span>{{ "ftiCalc.calculator-thirty-year" | translate }}</span>
    </div>
  </div>

  <!-- Form-5: Expected ROI -->
  <div class="form-group " style="margin-bottom: 0px;">
    <label for="ref-expectedReturn" class="form-label">{{ "ftiCalc.calculator-rate-of-return" | translate }}
      <div class="selected-range"  (click)="autoFocus($event, 'ref-expectedReturn')">
        <input
            type="number"
            [attr.min]="getInputLimit(sliderKeyName.expectedReturn, false)"
            [attr.max]="getInputLimit(sliderKeyName.expectedReturn, true)"
            formControlName="expectedReturn"
            [(ngModel)]="investmentCalcForm.value.expectedReturn"
            (change)="onCalculatorChange($event, 'expectedReturn')"
            class="calc-input"  id="ref-expectedReturn" aria-labelledby="perc">
        <span class="calc-input-prefix" id="perc"> %</span>
      </div>
    </label>
    <div class="range-slider">
      <input
          #expectedReturnSlider
          type="range"
          formControlName="expectedReturn"
          [(ngModel)]="investmentCalcForm.value.expectedReturn"
          (change)="onCalculatorChange($event, 'expectedReturn')"
          class="custom-range"
          [attr.min]="getInputLimit(sliderKeyName.expectedReturn, false)"
          [attr.max]="getInputLimit(sliderKeyName.expectedReturn, true)"
          [style.background]="expectedReturnSliderStyle"
          aria-labelledby="calcPer"
      >
    </div>
    <div id="calcPer" class="d-flex text-small justify-content-between">
      <span>{{ "ftiCalc.calculator-eight" | translate }}</span>
      <span>{{ "ftiCalc.calculator-fifteen" | translate }}</span>
    </div>
  </div>
</form>

